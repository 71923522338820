import Button from '@/components/ui/Button';
import { throwIfUnauthenticatedCustomer } from '@/utils/narrow-utils';
import { useQuery } from '@apollo/client';
import Image from 'next/image';
import { useCallback, useRef, useState } from 'react';
import { gql } from 'src/__generated__';
import { OfferedServices } from 'src/__generated__/graphql';
import { ServiceList } from './ServiceList';
import { BookService } from './BookService';
import useBreakpoint from '@/hooks/useBreakpoint';
import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import { tisaSans } from '@/pages/_app.page';
import colors from '@/utils/colors';
import Icon from '@/components/ui/icon';
import { useBrandData } from '@/hooks/useBrandData';

type WaitListAdProps = {
  isOpen: boolean;
  close: () => void;
};

const WaitListQuery = gql(/* GraphQL */ `
  query GetWaitlistForCustomer {
    me {
      ... on Customer {
        id
        rawId
        ...WaitListedServices
        ...CustomerLocation
      }
    }
  }
`);

export function WaitListAd({ isOpen, close }: WaitListAdProps) {
  const { appName } = useBrandData();
  const { loading, data: rawData } = useQuery(WaitListQuery);
  const data = throwIfUnauthenticatedCustomer(rawData);
  const [service, setService] = useState<OfferedServices | undefined>();
  const [step, setStep] = useState<'ad' | 'book'>('ad');
  const { breakpoint } = useBreakpoint();
  let closeModalButtonRef = useRef(null);
  const [booking, setBooking] = useState(false);
  const [booked, setBooked] = useState(false);

  const closeModal = useCallback(() => {
    close();
    setStep('ad');
    setService(undefined);
    setBooking(false);
    setBooked(false);
  }, [close]);

  const fullscreen =
    breakpoint !== 'lg' && step !== 'ad' && !booking && !booked;

  return (
    <Dialog open={isOpen} onClose={close} className={tisaSans.className}>
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-lightGray bg-opacity-80 z-[150]" />

      {/* Full-screen container to center the panel */}
      <div
        className={clsx(
          'fixed inset-0 flex h-full w-full overflow-y-auto justify-center items-center z-[200]',
          fullscreen ? '' : 'sm:p-2 lg:p-4',
        )}
      >
        <Dialog.Panel
          className={clsx(
            `relative bg-white border border-gray overflow-hidden text-gray-darker`,
            fullscreen
              ? 'flex w-full h-full'
              : 'w-[640px] h-fit max-h-[90%] rounded-[10px] sm:m-2 lg:m-8 overflow-y-scroll',
          )}
        >
          {booking && (
            <div className="flex flex-col justify-center items-center text-center w-full overflow-hidden relative bg-white px-4 lg:px-6 py-8 lg:py-6 ">
              <Icon
                name="spinner"
                size={40}
                className="mb-6 animate-spin"
                color={colors.brand.purple}
              />

              <div className="flex flex-col items-start mb-2">
                <p className="text-xl font-bold text-center">
                  Looking for a pro...
                </p>
              </div>
            </div>
          )}

          {booked && (
            <div className="flex flex-col justify-center items-center text-center w-full overflow-hidden relative bg-white px-4 lg:px-6 py-8 lg:py-6 ">
              <div className="rounded-full overflow-clip flex flex-col p-3 bg-lightestGray mb-6">
                <Icon name="hourglass" size={40} color={colors.brand.purple} />
              </div>
              <div className="flex flex-col items-start mb-2">
                <p className="text-xl font-bold text-center">
                  We don&#39;t have any available pros right now
                </p>
              </div>
              <p className="font-normal text-md mb-6">
                Don&#39;t worry! We&#39;ve added you to our waitlist, and
                you&#39;ll be notified first when we add more pros to Redwood
                City.
              </p>
              <Button
                onClick={() => closeModal()}
                variant="primary"
                className="self-center"
              >
                Ok, got it!
              </Button>
            </div>
          )}
          {step === 'book' && data?.me && service && !booked && !booking && (
            <BookService
              service={service}
              customer={data.me}
              clearService={() => setService(undefined)}
              setBooking={setBooking}
              setBooked={setBooked}
            />
          )}
          {step === 'book' && data?.me && !service && (
            <ServiceList onServiceSelect={setService} customer={data.me} />
          )}
          {(step === 'ad' || !data?.me) && (
            <div className="w-full flex flex-col justify-center items-center px-4 py-6">
              <Image
                alt={`Person showing different benefits of their ${appName} membership`}
                className="max-w-[220px] mt-4"
                src={require('./customer_offered_services.png')}
              />
              <div className="mt-6 flex flex-col items-start w-full">
                <h3 className="font-medium text-md text-purple">New!</h3>
                <h1 className="font-bold text-xl">
                  Find pros for home services
                </h1>
                <p className="mb-6 mt-2 text-md font-normal">
                  Book a dog walker, home organizer, car washer and more.
                </p>
                <Button
                  variant="primary"
                  loading={loading}
                  disabled={loading}
                  className="w-full"
                  onClick={() => setStep('book')}
                >
                  Learn more
                </Button>
              </div>
            </div>
          )}
          <button
            aria-label="close modal"
            className="ring-0 outline-none absolute top-6 right-2 lg:top-4 lg:right-4 flex hover:bg-lightGray rounded p-2"
            onClick={closeModal}
            ref={closeModalButtonRef}
          >
            <Icon name="x" size={20} color={colors.brand.black} />
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
