import { useMutation } from '@apollo/client';
import toast, { UNKNOWN_ERROR } from '@/utils/toast';
import * as Sentry from '@sentry/nextjs';
import { gql } from 'src/__generated__';
import { useState } from 'react';
import Mixpanel from '@/utils/mixpanel';

export const ASK_ETA = gql(/* GraphQL */ `
  mutation CustomerAskETAHook($input: CustomerAskETAInput!) {
    customerAskEta(input: $input) {
      success
      message
      job {
        id
        customerRequestedEta
      }
    }
  }
`);

const useRequestETA = ({
  jobId,
  start,
  cleanerFirstName = 'your cleaner',
}: {
  jobId: string;
  start: Date;
  cleanerFirstName?: string | null;
}): {
  requestETA: () => void;
  isETARequestLoading: boolean;
  customerRequestedEta: boolean;
} => {
  const [customerRequestedEta, setCustomerRequestedEta] = useState(false);

  const [requestETA, { loading: isETARequestLoading }] = useMutation(ASK_ETA, {
    variables: {
      input: {
        jobId,
      },
    },
    onCompleted(data) {
      if (data?.customerAskEta?.success === false) {
        toast.error(data?.customerAskEta?.message || UNKNOWN_ERROR);
        return;
      }
      if (data?.customerAskEta?.success === true) {
        toast.success(`We've messaged ${cleanerFirstName} asking for an ETA`);
        setCustomerRequestedEta(true);

        Mixpanel.track('job_ETA_request_success', {
          jobId,
          eventTimeRelativeToJobStart:
            (new Date().getTime() - new Date(start).getTime()) / 1000 / 60 / 60,
        });
      }
    },
    onError(error) {
      Sentry.captureException(error);
      toast.error(UNKNOWN_ERROR);
    },
  });

  return { requestETA, isETARequestLoading, customerRequestedEta };
};

export default useRequestETA;
