/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    screens: {
      sm: '0px', // sm & up
      md: '375px', // md & up
      lg: '768px', // lg & up
    },
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '18px',
      xl: '20px',
      '2xl': '24px',
      '3xl': '30px',
      '4xl': '36px',
      '5xl': '48px',
    },
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      lightestRed: '#E0A99E',
      red: '#E1745D',
      darkerRed: '#C2553E',
      maroon: '#780000',
      orange: '#EBA670',
      darkYellow: '#E3B923',
      yellow: '#F5C34A',
      brightYellow: '#FCCF27',
      lightGreen: '#E6FFD7',
      green: '#D6E28E',
      darkGreen: '#53a318',
      darkerGreen: '#1F730A',
      teal: '#92E0C1',
      blue: '#33a6f8',
      darkBlue: '#007bff',
      darkerBlue: '#0056b3',
      lightestPurple: '#EFF1F9',
      lightPurple: '#D2E8FC',
      purple: '#5E71C4',
      darkPurple: '#4B5BA1',
      magenta: '#BC4088',
      darkPlum: '#430a37',
      lightestGray: '#F7F7F7',
      lightGray: '#E4E3E3',
      gray: '#DDDDDD',
      grayStrong: '#BEBFC4',
      darkGray: '#707070',
      darkerGray: '#424242',
      transparent: 'transparent',
    },
    extend: {},
  },
  plugins: [require('@tailwindcss/forms')],
};
